const titleLength = 255
export const validateQuiz = values => {
  const errors = {}

  if (!values.title) {
    errors.title = 'Ce champ est requis'
  }

  if (values.title.length > titleLength) {
    errors.title = `Titre limité a ${titleLength} caractères, vous avez indiqué ${values.title.length} caractères`
  }

  if (!values?.theme?.id) {
    errors.theme = {
      id: 'Ce champ est requis',
    }
  }

  if (!values.releaseDate) {
    errors.releaseDate = 'Ce champ est requis'
  }

  if (values.questions) {
    values.questions.forEach((question, questionIndex) => {

      const handleErrorQuestion = (field, message) => errors.questions = {
        ...errors?.questions ?? {},
        [questionIndex]: {
          ...errors?.questions?.[questionIndex] ?? {},
          [field]: message,
        },
      }

      if (!question.topic) {
        handleErrorQuestion('topic', 'Ce champ est requis')
      }

      if (question.topic.length > titleLength) {
        handleErrorQuestion('topic', `Le sujet de la question est limité a ${titleLength} caractères, vous avez indiqué ${question.topic.length} caractères`, 'questions', questionIndex)
      }

      if (!question.title) {
        handleErrorQuestion('title', 'Ce champ est requis')
      }

      if (question.title.length > titleLength) {
        handleErrorQuestion('title', `Le titre de la question est limité a ${titleLength} caractères, vous avez indiqué ${question.title.length} caractères`, 'questions', questionIndex)
      }

      if (!question.type) {
        handleErrorQuestion('type', 'Ce champ est requis', 'questions', questionIndex)
      }

      if (!question.feedback) {
        handleErrorQuestion('feedback', 'Ce champ est requis', 'questions', questionIndex)
      }

      if (question.answers) {
        const hasValidAnswer = question.answers.filter(answer => answer?.valid).length > 0
        const isBothValidAndImportant = answer => (answer?.valid && answer?.important)
        const countValidAnswers = question.answers.filter(answer => answer?.valid).length

        question.answers.forEach((answer, answerIndex) => {

          const handleErrorAnswer = (field, message) => errors.questions = {
            ...errors?.questions ?? {},
            [questionIndex]: {
              ...errors?.questions?.[questionIndex] ?? {},
              answers: {
                ...errors?.questions?.[questionIndex]?.answers ?? {},
                [answerIndex]: {
                  ...errors?.questions?.[questionIndex]?.answers?.[answerIndex] ?? {},
                  [field]: message,
                },
              },
            },
          }

          if (!answer.title) {
            handleErrorAnswer('title', 'Ce champs est requis')
          }

          if (answer.title.length > titleLength) {
            handleErrorAnswer('title', `Le titre de la réponse est limité a ${titleLength} caractères, vous avez indiqué ${answer.title.length} caractères`)
          }

          if ('SINGLE_CHOICE' === question.type) {
            if (countValidAnswers > 1) {
              handleErrorAnswer('valid', 'Une question à choix unique ne peut avoir qu\'une seule réponse valide.')
            }
          }

          if (isBothValidAndImportant(answer)) {
            handleErrorAnswer('important', 'Une réponse ne peut pas être à la fois valide et grave.')
          }

          if (!hasValidAnswer) {
            handleErrorAnswer('valid', 'La question doit comporter au moins 1 réponse valide.')
          }
        })
      }
    })
  }

  return errors
}
