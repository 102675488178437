import { mapDataToAddressInput } from 'data-mapper/address'

import { dateToISOStringWithoutExtension } from '../utils/date'

export const mapDataToShortUserInput = ({ id }) => ({ id })

export const mapDataToUserInput = user => ({
  id: user.id,
  gender: user.gender,
  firstname: user.firstname,
  lastname: user.lastname,
  username: user.username,
  plainPassword: user.plainPassword,
  email: user.email,
  type: user.type,
  rpps: user.rpps,
  job: user.job,
  personalPhone: user.personalPhone,
  address: user.address ? mapDataToAddressInput(user.address) : null,
  billingAddress: user.billingAddress ? mapDataToAddressInput(user.billingAddress) : null,
  organization: user.organization,
  activatedAt: user.activatedAt ? dateToISOStringWithoutExtension(user.activatedAt) : null,
  pharmacy: user.pharmacy ? user.pharmacy.id : null,
  switchToken: user.switchToken ? user.switchToken.authToken : null,
})

export const mapDataToCreateUserInput = user => ({
  firstname: user.firstname,
  lastname: user.lastname,
  username: user.username,
  plainPassword: user.plainPassword,
  email: user.email,
  type: user.type,
  rpps: user.rpps,
  job: user.job,
  birthdate: user.birthdate,
})

export const mapDataToEditUserInput = user => ({
  gender: user.gender,
  firstname: user.firstname,
  lastname: user.lastname,
  username: user.username,
  plainPassword: user.plainPassword,
  email: user.email,
  type: user.type,
  rpps: user.rpps,
  job: user.job,
  birthdate: user.birthdate,
  personalPhone: user.personalPhone,
  address: user.address ? mapDataToAddressInput(user.address) : null,
  billingAddress: user.billingAddress ? mapDataToAddressInput(user.billingAddress) : null,
  organization: user.organization,
  pharmacyId: user.pharmacy ? user.pharmacy.id : null,
})
