import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'
import { DELETE, GET_LIST, GET_ONE } from 'react-admin'

const getList = params => apolloClient
  .query({
    query: gql`
        query ($page: Int, $perPage: Int) {
            allAccountDeletionRequests(page: $page, perPage: $perPage) {
                id
                user {
                  id
                  firstname
                  lastname
                  email
                  subscription {
                    id
                    user {
                      id
                      email
                    }
                  }
                }
            }
            _allAccountDeletionRequestsMeta{
              count
            }
        }
    `,
    variables: { ...params.pagination },
  })
  .then(({ data }) => ({
    data: data.allAccountDeletionRequests,
    total: data._allAccountDeletionRequestsMeta.count,
  }))

const getOne = params => apolloClient
  .query({
    query: gql`
        query ($id: ID!) {
            accountDeletionRequest(id: $id) {
                id
                user {
                  id
                  firstname
                  lastname
                  email
                }
            }
        }
    `,
    variables: {
      id: params.id,
    },
  })
  .then(({ data }) => ({
    data: data.accountDeletionRequest,
  }))

const deleteOne = params => apolloClient
  .mutate({
    mutation: gql`
      mutation ($id: ID!) {
        deleteRequestAccountDeletion(id: $id) {
          id
        }
      }
    `,
    variables: {
      id: params.id,
    },
  })

export default (type, params) => {
  switch (type) {
    case GET_LIST: return getList(params)
    case GET_ONE: return getOne(params)
    case DELETE: return deleteOne(params)
    default: return new Promise()
  }
}
