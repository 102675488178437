import DescriptionIcon from '@mui/icons-material/Description'
import React from 'react'
import {
  DeleteButton,
  EmailField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
} from 'react-admin'

const AccountDeletionToolbar = () => {
  return (
    <TopToolbar>
      {
        <DeleteButton resource="accountDeletionRequest" />
      }
    </TopToolbar>
  )
}

const AccountDeletionRequestShow = props => {
  return (
    <Show
      actions={ <AccountDeletionToolbar /> }
      { ...props }
    >
      <TabbedShowLayout>
        <Tab
          icon={ <DescriptionIcon /> }
          label="Résumé"
        >
          <TextField
            label="ID"
            sortable={ false }
            source="id"
          />
          <TextField
            label="Prénom"
            sortable={ false }
            source="user.firstname"
          />
          <TextField
            label="Nom"
            sortable={ false }
            source="user.lastname"
          />
          <EmailField
            label="Email"
            sortable={ false }
            source="user.email"
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default AccountDeletionRequestShow
