import React from 'react'
import {
  DateField,
  RichTextField,
  Show,
  SimpleShowLayout,
} from 'react-admin'

const DailyQuestionShow = props => (
  <Show { ...props }>
    <SimpleShowLayout>
      <RichTextField
        label="Intitulé"
        source="title"
      />
      <RichTextField
        label="Réponse"
        source="answer"
      />
      <DateField
        label="Publication"
        locales="fr-FR"
        source="createdAt"
        showTime
      />
    </SimpleShowLayout>
  </Show>
)

export default DailyQuestionShow
