import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './components/App'

const elementRoot = document.getElementById('root')

if (elementRoot === null) {
  throw new Error('Cannot find root element.')
}

const root = createRoot(elementRoot)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
