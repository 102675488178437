import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'
import { Buffer } from 'buffer'

const unauthorizedStatusCode = [401, 403]

const authenticate = gql`
  mutation {
    authentication {
      authToken
    }
  }
`

const me = gql`
  query {
    me {
      id
      firstname
      lastname
      username
      type
      email
    }
  }
`

const authProvider = {
  login: ({ username, password }) => apolloClient
    .mutate({
      mutation: authenticate,
      context: {
        headers: {
          authorization: `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`,
        },
      },
    })
    .then(({ data: { authentication: { authToken } } }) =>
      localStorage.setItem('authToken', authToken),
    ),
  logout: () => {
    localStorage.removeItem('authToken')

    return Promise.resolve()
  },
  checkError: error => {
    if(unauthorizedStatusCode.includes(error?.networkError?.statusCode)) {
      localStorage.removeItem('authToken')
      return Promise.reject()
    }

    return Promise.resolve()
  },
  checkAuth: async () => {
    const token = localStorage.getItem('authToken')

    if (!token) {
      return Promise.reject()
    }

    const user = await apolloClient.query({ query: me })

    if(user?.data?.me && user?.data?.me?.type === 'admin') {
      return Promise.resolve()
    }

    return Promise.reject()
  },
  getIdentity: async () => {
    const token = localStorage.getItem('authToken')

    if (!token) {
      return Promise.reject()
    }

    const user = await apolloClient.query({ query: me })

    if(user?.data?.me && user?.data?.me?.type === 'admin') {
      return Promise.resolve({
        fullName: user?.data?.me?.email,
      })
    }

    return Promise.reject()
  },
  getPermissions: () => Promise.resolve(),
}

export default authProvider
