import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'
import { GET_LIST, GET_ONE, UPDATE } from 'react-admin'

const getList = params => apolloClient
  .query({
    query: gql`
        query ($page: Int!, $perPage: Int!, $sortField: String!, $sortOrder: String!, $filter: CommentFilter!) {
            allComments(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
                id
                content
                createdAt
                checking
                user {
                    id
                    username
                    email
                }
                question {
                    id
                    title
                    position
                    quiz {
                        id
                        title
                    }
                }
            }
            _allCommentsMeta(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
                count
            }
        }
    `,
    variables: {
      ...params.pagination,
      sortField: params.sort.field,
      sortOrder: params.sort.order,
      filter: params.filter,
    },
  })
  .then(({ data }) => ({
    data: data.allComments,
    total: data._allCommentsMeta.count,
  }))

const getOne = params => apolloClient
  .query({
    query: gql`
        query ($id: ID!) {
            comment(id: $id) {
                id
                content
                createdAt
                checking
                user {
                    id
                    username
                    email
                }
                question {
                    id
                    title
                    position
                    feedback
                    quiz {
                        id
                        title
                    }
                }
            }
        }
    `,
    variables: {
      id: params.id,
    },
  })
  .then(({ data }) => ({
    data: data.comment,
  }))

const update = params => apolloClient
  .mutate({
    mutation: gql`
        mutation ($id: ID!, $checking: Boolean!) {
            updateComment(id: $id, checking: $checking) {
                id
            }
        }
    `,
    variables: {
      id: params.id,
      checking: params.data.checking,
    },
  })
  .then(({ data }) => ({
    data: data.updateComment,
  }))

export default (type, params) => {
  switch (type) {
    case GET_LIST: return getList(params)
    case GET_ONE: return getOne(params)
    case UPDATE: return update(params)
    default: return new Promise()
  }
}
