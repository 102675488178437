import { ApolloProvider } from '@apollo/client'
import RankingIcon from '@mui/icons-material/Assessment'
import AssignmentIcon from '@mui/icons-material/Assignment'
import CouponIcon from '@mui/icons-material/CardGiftcard'
import ReminderIcon from '@mui/icons-material/Code'
import CommentIcon from '@mui/icons-material/Comment'
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol'
import HelpIcon from '@mui/icons-material/Help'
import PharmacyIcon from '@mui/icons-material/LocalPharmacy'
import UserIcon from '@mui/icons-material/Person'
import PersonRemove from '@mui/icons-material/PersonRemove'
import SubscriptionIcon from '@mui/icons-material/ShoppingBasket'
import authProvider from 'authProvider'
import CommentEdit from 'components/comment/Edit'
import CommentList from 'components/comment/List'
import CommentShow from 'components/comment/Show'
import CouponList from 'components/coupon/List'
import CouponShow from 'components/coupon/Show'
import DailyQuestionCreate from 'components/dailyQuestion/Create'
import DailyQuestionEdit from 'components/dailyQuestion/Edit'
import DailyQuestionList from 'components/dailyQuestion/List'
import DailyQuestionShow from 'components/dailyQuestion/Show'
import CreatePharmacy from 'components/pharmacy/Create'
import EditPharmacy from 'components/pharmacy/Edit'
import PharmacyList from 'components/pharmacy/List'
import PharmacyShow from 'components/pharmacy/Show'
import CreatePlan from 'components/plan/Create'
import EditPlan from 'components/plan/Edit'
import PlanList from 'components/plan/List'
import ProductList from 'components/product/List'
import CreateQuiz from 'components/quiz/Create'
import EditQuiz from 'components/quiz/Edit'
import QuizList from 'components/quiz/List'
import QuizShow from 'components/quiz/Show'
import RankingList from 'components/ranking/List'
import ReminderList from 'components/reminder/List'
import ReminderShow from 'components/reminder/Show'
import CreateSubscription from 'components/subscription/Create'
import EditSubscription from 'components/subscription/Edit'
import ListSubscription from 'components/subscription/List'
import ShowSubscription from 'components/subscription/Show'
import TaxRateList from 'components/taxRate/List'
import CreateTheme from 'components/theme/Create'
import EditTheme from 'components/theme/Edit'
import ThemeList from 'components/theme/List'
import ThemeShow from 'components/theme/Show'
import CreateUser from 'components/user/Create'
import EditUser from 'components/user/Edit'
import UserList from 'components/user/List'
import UserShow from 'components/user/Show'
import dataProvider from 'data-provider'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import frenchMessages from 'ra-language-french'
import React from 'react'
import { Admin, CustomRoutes, defaultTheme, Resource } from 'react-admin'
import { Route } from 'react-router-dom'

import client from '../apolloClient'
import AccountDeletionRequestsList from './accountDeletionRequest/List'
import AccountDeletionRequestShow from './accountDeletionRequest/Show'
import Export from './exports/Export'
import { CustomLayout } from './layout'
import CustomLoginPage from './login'
import CreateInternal from './subscription/CreateInternal'

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr')
const darkTheme = CreateTheme({
  palette: { mode: 'dark' },
})

const App = () => (
  <ApolloProvider client={ client }>
    <Admin
      lightTheme={ defaultTheme }
      darkTheme={ darkTheme }
      authProvider={ authProvider }
      dataProvider={ dataProvider }
      i18nProvider={ i18nProvider }
      defaultTheme={ defaultTheme }
      layout={ CustomLayout }
      loginPage={ CustomLoginPage }
    >
      <Resource create={ CreateTheme } edit={ EditTheme } list={ ThemeList } name="theme" options={ { label: 'Themes' } } show={ ThemeShow } />
      <Resource create={ CreateQuiz } edit={ EditQuiz } icon={ AssignmentIcon } list={ QuizList } name="quiz" options={ { label: 'Quiz' } } show={ QuizShow } />
      <Resource create={ CreateUser } edit={ EditUser } icon={ UserIcon } list={ UserList } name="user" options={ { label: 'Utilisateurs' } } show={ UserShow } />
      <Resource create={ CreateSubscription } edit={ EditSubscription } icon={ SubscriptionIcon } list={ ListSubscription } name="subscription" options={ { label: 'Abonnements' } } show={ ShowSubscription } />
      <Resource edit={ CommentEdit } icon={ CommentIcon } list={ CommentList } name="comment" options={ { label: 'Remarques' } } show={ CommentShow } />
      <Resource list={ ProductList } name="product" options={ { label: 'Produits' } } />
      <Resource list={ TaxRateList } name="taxRate" options={ { label: 'TaxRates' } } />
      <Resource create={ CreatePlan } edit={ EditPlan } icon={ EuroSymbolIcon } list={ PlanList } name="plan" options={ { label: 'Plans' } } />
      <Resource icon={ CouponIcon } list={ CouponList } name="coupon" options={ { label: 'Coupons' } } show={ CouponShow } />
      <Resource create={ CreatePharmacy } edit={ EditPharmacy } icon={ PharmacyIcon } list={ PharmacyList } name="pharmacy" options={ { label: 'Pharmacies' } } show={ PharmacyShow } />
      <Resource icon={ RankingIcon } list={ RankingList } name="ranking" options={ { label: 'Classements' } } />
      <Resource icon={ ReminderIcon } list={ ReminderList } name="reminder" options={ { label: 'Logs' } } show={ ReminderShow } />
      <Resource create={ DailyQuestionCreate } edit={ DailyQuestionEdit } icon={ HelpIcon } list={ DailyQuestionList } name="dailyQuestion" options={ { label: 'Question du jour' } } show={ DailyQuestionShow } />
      <Resource icon={ PersonRemove } list={ AccountDeletionRequestsList } name="accountDeletionRequest" options={ { label: 'Suppression de compte' } } show={ AccountDeletionRequestShow } />
      <CustomRoutes>
        <Route path="/subscription/create-internal" element={ <CreateInternal /> } />
        <Route path="/exports" element={ <Export /> } />
      </CustomRoutes>
    </Admin>
  </ApolloProvider>
)

export default App
