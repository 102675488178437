import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'
import { mapGetListVariables, mapGetManyVariables } from 'data-mapper'
import { GET_LIST, GET_MANY, GET_ONE } from 'react-admin'

const getList = (type, params) => apolloClient
  .query({
    query: gql`
      query ($filter: TaxRateFilter) {
        allTaxRates(filter: $filter) {
          id
          name
          percentage
          stripeId
        }
      }
    `,
    variables: type === GET_MANY ? mapGetManyVariables(params) : mapGetListVariables(params),
  })
  .then(({ data }) => ({
    data: data.allTaxRates,
    total: data.allTaxRates.length,
  }))

const getOne = params => apolloClient
  .query({
    query: gql`
      query ($id: ID!) {
        taxRate(id: $id) {
          id
          name
          stripeId
        }
      }
    `,
    variables: {
      id: params.id,
    },
  })
  .then(({ data }) => ({
    data: data.taxRate,
  }))

export default (type, params) => {
  switch (type) {
    case GET_LIST: return getList(type, params)
    case GET_MANY: return getList(type, params)
    case GET_ONE: return getOne(params)
    default: return new Promise()
  }
}
