import React from 'react'
import { Datagrid, DateField, EmailField, List, TextField } from 'react-admin'

const ReminderList = props => (
  <List
    { ...props }
    title="Logs"
  >
    <Datagrid rowClick="show" bulkActionButtons={ false }>
      <TextField
        label="Nom"
        sortable={ false }
        source="user.lastname"
      />
      <TextField
        label="Prénom"
        sortable={ false }
        source="user.firstname"
      />
      <EmailField
        label="Email"
        sortable={ false }
        source="user.email"
      />
      <TextField
        label="Nom d'utilisateur"
        sortable={ false }
        source="user.username"
      />
      <TextField
        label="Nb de jours avant expiration"
        sortable={ false }
        source="days"
      />
      <DateField
        label="Date d'envoi"
        sortable={ false }
        source="createdAt"
      />
    </Datagrid>
  </List>
)

export default ReminderList
