import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'
import { mapGetListVariables, mapGetManyVariables } from 'data-mapper'
import { mapDataToCreateUserInput, mapDataToEditUserInput } from 'data-mapper/user'
import { GET_USER, GET_USER_STATISTICS } from 'graphql/queries/users'
import { CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE } from 'react-admin'

const getList = (type, params) => apolloClient
  .query({
    query: gql`
        query ($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: UserFilter) {
            allUsers(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
                id
                stripeId
                gender
                firstname
                lastname
                username
                email
                avatar
                type
                rpps
                job
                personalPhone
                address {
                  address
                  address2
                  zipCode
                  city
                  country
                }
                billingAddress {
                  address
                  address2
                  zipCode
                  city
                  country
                }
                badge
                createdAt
                activatedAt
                organization
                pharmacy {
                  id
                  name
                }
            }
            _allUsersMeta(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
                count
            }
        }
    `,
    variables: type === GET_MANY ? mapGetManyVariables(params) : mapGetListVariables(params),
  })
  .then(({ data }) => ({
    data: data.allUsers,
    total: data._allUsersMeta.count,
  }))

const getOne = async params => {
  const queryName = params.query?.name ?? 'GET_USER'

  const queries = {
    GET_USER: GET_USER,
    GET_USER_STATISTICS: GET_USER_STATISTICS,
  }

  return apolloClient
    .query({
      query: queries[queryName],
      variables: {
        id: params.id,
      },
    }).then(({ data }) => ({
      data: data.user,
    }))
}

const create = params => apolloClient
  .mutate({
    mutation: gql`
        mutation ($user: CreateUserInput!) {
            createUser(user: $user) {
                id
            }
        }
    `,
    variables: {
      user: mapDataToCreateUserInput(params.data),
    },
  })
  .then(({ data }) => ({
    data: data.createUser,
  }))

const update = params => apolloClient
  .mutate({
    mutation: gql`
        mutation ($id: ID!, $user: EditUserInput!) {
            updateUser(id: $id, user: $user) {
                id
                gender
                firstname
                lastname
                username
                birthdate
                email
                type
                rpps
                job
                personalPhone
                address {
                  address
                  address2
                  zipCode
                  city
                  country
                }
                billingAddress {
                  address
                  address2
                  zipCode
                  city
                  country
                }
                badge
                activatedAt
                organization
                pharmacy {
                  id
                  name
                }
            }
        }
    `,
    variables: {
      id: params.id,
      user: mapDataToEditUserInput(params.data),
    },
  })
  .then(({ data }) => ({
    data: data.updateUser,
  }))

const remove = params => apolloClient
  .mutate({
    mutation: gql`
        mutation ($id: ID!) {
            anonymizeUser(id: $id) {
                id
            }
        }
    `,
    variables: {
      id: params.id,
    },
  })
  .then(({ data }) => ({
    data: data.anonymizeUser,
  }))

export default (type, params) => {
  switch (type) {
    case GET_LIST: return getList(type, params)
    case GET_MANY: return getList(type, params)
    case GET_ONE: return getOne(params)
    case CREATE: return create(params)
    case UPDATE: return update(params)
    case DELETE: return remove(params)
    default: return new Promise()
  }
}
