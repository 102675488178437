import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import DescriptionIcon from '@mui/icons-material/Description'
import React from 'react'
import {
  ArrayField,
  Datagrid,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
} from 'react-admin'

const ThemeShow = props => (
  <Show { ...props }>
    <TabbedShowLayout>
      <Tab
        icon={ <DescriptionIcon /> }
        label="Résumé"
      >
        <TextField
          label="Identifiant"
          source="id"
        />
        <TextField
          label="Titre du theme"
          source="title"
        />
      </Tab>

      <Tab
        icon={ <AssignmentTurnedInIcon /> }
        label="Essentiel"
      >
        <ArrayField
          label="Essentiels"
          source="essential.sections"
        >
          <Datagrid bulkActionButtons={ false }>
            <TextField
              label="Titre"
              source="title"
            />
            <TextField
              label="Contenu"
              source="content"
            />
          </Datagrid>
        </ArrayField>

        <ArrayField
          label="Bibliographie"
          source="essential.bibliography"
        >
          <Datagrid bulkActionButtons={ false }>
            <TextField
              label="Titre"
              source="title"
            />
            <UrlField
              label="Lien"
              source="link"
            />
            <NumberField
              label="Position"
              source="position"
            />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default ThemeShow
