import React from 'react'
import {
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'

const CreatePlan = props => (
  <Create { ...props }>
    <SimpleForm>
      <TextInput
        label="ID du plan"
        source="id"
        validate={ required() }
        fullWidth
      />
      <TextInput
        label="Nom du plan"
        source="title"
        validate={ required() }
        fullWidth
      />
      <TextInput
        label="Description"
        source="description"
        multiline
      />
      <TextInput
        label="ID Apple"
        source="appleId"
        fullWidth
      />
      <TextInput
        label="ID Google"
        source="googleId"
        fullWidth
      />
      <ReferenceInput
        label="Produit"
        reference="product"
        source="product.id"
      >
        <SelectInput optionText="title" validate={ required() } />
      </ReferenceInput>
      <ReferenceInput
        label="Taux de TVA"
        reference="taxRate"
        source="taxRate.id"
      >
        <SelectInput optionText="name" validate={ required() } />
      </ReferenceInput>
      <NumberInput
        format={ amount => amount / 100 }
        label="Prix (en €)"
        parse={ amount => Math.round(amount * 100) }
        source="amount"
        validate={ required() }
        fullWidth
      />
      <SelectInput
        choices={ [
          { id: 'month', name: 'Mensuel' },
          { id: 'year', name: 'Annuel' },
        ] }
        label="Récurrence"
        source="interval"
        validate={ required() }
      />
    </SimpleForm>
  </Create>
)

export default CreatePlan
