import DescriptionIcon from '@mui/icons-material/Description'
import PeopleIcon from '@mui/icons-material/People'
import React from 'react'
import {
  ArrayField,
  Datagrid,
  DateField,
  EmailField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin'

const PharmacyShow = props => (
  <Show { ...props }>
    <TabbedShowLayout>
      <Tab
        icon={ <DescriptionIcon /> }
        label="Résumé"
      >
        <TextField
          label="Identifiant de la pharmacie"
          source="id"
        />
        <TextField
          label="Nom de la pharmacie"
          source="name"
        />
        <TextField
          label="Adresse de la pharmacie"
          source="address"
        />
        <TextField
          label="Code postal de la pharmacie"
          source="zipCode"
        />
        <TextField
          label="Ville de la pharmacie"
          source="city"
        />
        <TextField
          label="Pays de la pharmacie"
          source="country"
        />
        <TextField
          label="Groupement"
          source="organization"
        />
        <DateField
          label="Date de creation"
          sortable={ false }
          source="createdAt"
        />
        <TextField
          label="Pharmacien titulaire"
          source="owner.username"
        />
      </Tab>
      <Tab
        icon={ <PeopleIcon /> }
        label="Membres"
      >
        <ArrayField
          label="Membres de la pharmacie"
          source="users"
        >
          <Datagrid bulkActionButtons={ false }>
            <TextField
              label="Nom"
              sortable={ false }
              source="lastname"
            />
            <TextField
              label="Prénom"
              sortable={ false }
              source="firstname"
            />
            <TextField
              label="Nom d'utilisateur"
              sortable={ false }
              source="username"
            />
            <EmailField
              label="Email"
              sortable={ false }
              source="email"
            />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default PharmacyShow
