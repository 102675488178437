import React from 'react'
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  EmailField,
  Filter,
  List,
  TextField,
} from 'react-admin'

const CommentFilter = props => (
  <Filter { ... props }>
    <BooleanInput label="Remarques lues"
      source="checking"
    />
  </Filter>
)

const CommentList = props => (
  <List
    { ...props }
    filters={ <CommentFilter /> }
    pagination={ null }
    title="Remarques"
  >
    <Datagrid rowClick="show" bulkActionButtons={ false }>
      <TextField
        label="Remarque"
        sortable={ false }
        source="content"
      />
      <TextField
        label="Signalé par"
        sortable={ false }
        source="user.username"
      />
      <EmailField
        label="Email"
        sortable={ false }
        source="user.email"
      />
      <TextField
        label="Quiz"
        sortable={ false }
        source="question.quiz.title"
      />
      <DateField
        label="Date de publication"
        sortable={ false }
        source="createdAt"
      />
      <TextField
        label="Numéro du feedback"
        sortable={ false }
        source="question.position"
      />
      <BooleanField
        label="Remarque verifiée"
        sortable={ false }
        source="checking"
      />
    </Datagrid>
  </List>
)

export default CommentList
