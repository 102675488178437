import { useQuery } from '@apollo/client'
import { ALL_JOBS } from 'graphql/queries/users'
import React from 'react'
import {
  AutocompleteInput,
  DateInput,
  Edit,
  email,
  maxLength,
  maxValue,
  minLength,
  ReferenceInput,
  regex,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'

const EditUser = props => {
  const allJobs = useQuery(ALL_JOBS)
  const jobs = allJobs.data?.allJobs?.map(job => ({ id: job.title, name: job.title })) ?? []

  return (
    <>
      <Edit { ...props }>
        <SimpleForm>
          <SelectInput
            choices={ [
              { id: 'male', name: 'Homme' },
              { id: 'female', name: 'Femme' },
            ] }
            label="Genre"
            source="gender"
          />
          <TextInput
            label="Nom d'utilisateur"
            source="username"
            validate={ required() }
            fullWidth
          />
          <TextInput
            label="Nom"
            source="lastname"
            validate={ required() }
            fullWidth
          />
          <TextInput
            label="Prénom"
            source="firstname"
            validate={ required() }
            fullWidth
          />
          <DateInput
            label="Date de naissance"
            source="birthdate"
            validate={ maxValue(new Date().toISOString().split('T')[0]) }
          />
          <TextInput
            label="E-mail"
            source="email"
            validate={ [required(), email()] }
            fullWidth
          />
          <TextInput
            label="Mot de passe"
            source="plainPassword"
            type="password"
            fullWidth
          />
          <SelectInput
            choices={ [
              { id: 'admin', name: 'Administrateur' },
              { id: 'user', name: 'Utilisateur' },
              { id: 'external', name: 'Externe' },
            ] }
            label="Type"
            source="type"
            validate={ required() }
          />
          <TextInput
            label="Numéro RPPS"
            source="rpps"
            validate={ [required(), minLength(11), maxLength(11)] }
            inputProps={ { maxLength: 11 } }
            fullWidth
          />
          <SelectInput
            choices={ jobs }
            label="Job"
            source="job"
            validate={ required() }
          />
          <TextInput
            label="Numéro de téléphone"
            source="personalPhone"
            fullWidth
          />

          <h3>Adresse</h3>

          <TextInput
            label="Adresse"
            source="address.address"
            fullWidth
          />
          <TextInput
            label="Adresse 2"
            source="address.address2"
            fullWidth
          />
          <TextInput
            label="Code postal"
            source="address.zipCode"
            validate={ regex(/^\d{5}$/) }
            fullWidth
          />
          <TextInput
            label="Ville"
            source="address.city"
            fullWidth
          />
          <TextInput
            label="Pays"
            source="address.country"
            fullWidth
          />

          <h3>Adresse de facturation</h3>

          <TextInput
            label="Adresse"
            source="billingAddress.address"
            fullWidth
          />
          <TextInput
            label="Adresse 2"
            source="billingAddress.address2"
            fullWidth
          />
          <TextInput
            label="Code postal"
            source="billingAddress.zipCode"
            validate={ regex(/^\d{5}$/) }
            fullWidth
          />
          <TextInput
            label="Ville"
            source="billingAddress.city"
            fullWidth
          />
          <TextInput
            label="Pays"
            source="billingAddress.country"
            fullWidth
          />
          <TextInput
            label="Groupement"
            source="organization"
            fullWidth
          />
          <ReferenceInput
            label="Pharmacie"
            reference="pharmacy"
            source="pharmacy.id"
            perPage={ 25 }
          >
            <AutocompleteInput fullWidth optionText="name" filterToQuery={ q => ({ search: q }) } />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </>
  )
}

export default EditUser
