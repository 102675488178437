import { useQuery } from '@apollo/client'
import { ALL_JOBS } from 'graphql/queries/users'
import React from 'react'
import {
  Create,
  DateInput,
  email,
  maxLength,
  maxValue,
  minLength,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'

const CreateUser = props => {
  const allJobs = useQuery(ALL_JOBS)
  const jobs = allJobs.data?.allJobs?.map(job => ({ id: job.title, name: job.title })) ?? []

  return (
    <>
      <Create { ...props }>
        <SimpleForm>
          <TextInput
            label="Nom d'utilisateur"
            source="username"
            validate={ required() }
            fullWidth
          />
          <TextInput
            label="Nom"
            source="lastname"
            validate={ required() }
            fullWidth
          />
          <TextInput
            label="Prénom"
            source="firstname"
            validate={ required() }
            fullWidth
          />
          <DateInput
            label="Date de naissance"
            source="birthdate"
            validate={ maxValue(new Date().toISOString().split('T')[0]) }
          />
          <TextInput
            label="E-mail"
            source="email"
            validate={ [required(), email()] }
            fullWidth
          />
          <TextInput
            label="Mot de passe"
            source="plainPassword"
            type="password"
            validate={ required() }
            fullWidth
          />
          <SelectInput
            choices={ [
              { id: 'admin', name: 'Administrateur' },
              { id: 'user', name: 'Utilisateur' },
              { id: 'external', name: 'Externe' },
            ] }
            label="Type"
            source="type"
            validate={ required() }
          />
          <TextInput
            label="Numéro RPPS"
            source="rpps"
            validate={ [required(), minLength(11), maxLength(11)] }
            inputProps={ { maxLength: 11 } }
          />
          <SelectInput
            choices={ jobs }
            label="Job"
            source="job"
            validate={ required() }
          />
        </SimpleForm>
      </Create>
    </>
  )
}

export default CreateUser
