import { gql, useMutation } from '@apollo/client'
import TrashIcon from '@mui/icons-material/Delete'
import {
  Button,
  styled,
} from '@mui/material'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import {
  BooleanInput,
  DateInput,
  SimpleForm,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin'

const EDIT_DPC_ACCESS = gql`
  mutation EditDPCAccess(
    $userId: ID!,
    $startDate: String!,
    $endDate: String!,
    $isCompleted: Boolean!,
  ) {
    editDpcAccess(
      userId: $userId,
      startDate: $startDate,
      endDate: $endDate,
      isCompleted: $isCompleted,
    ) {
      startDate
      endDate
      isCompleted
    }
  }
`

const REVOKE_DPC_ACCESS = gql`
  mutation RevokeDPCAccess($userId: ID!) {
    revokeDpcAccess(userId: $userId) {
      id
    }
  }
`

const Toolbar = ({ data }) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [save] = useMutation(EDIT_DPC_ACCESS)
  const [revoke] = useMutation(REVOKE_DPC_ACCESS)

  const handleSave = async () => {
    const {
      userId,
      startDate,
      endDate,
      isCompleted,
    } = data

    if (!userId || !startDate || !endDate || isCompleted === undefined) {
      notify('Veuillez remplir tous les champs', { type: 'error' })

      return
    }

    await save({
      variables: data,
    })

    notify('Modifications enregistrées', { type: 'success' })
    refresh()
  }

  const handleRevoke = async () => {
    await revoke({
      variables: {
        userId: data.userId,
      },
    })

    notify('Accès DPC révoqué', { type: 'success' })
    refresh()
    redirect(`/user/${data.userId}/show`)
  }

  return (
    <ToolbarContainer>
      <Button
        variant="contained"
        color="primary"
        style={ {
          marginTop: 20,
        } }
        onClick={ handleSave }
      >
        {'Débloquer l\'accès DPC'}
      </Button>
      <Button
        variant="contained"
        color="error"
        style={ {
          marginTop: 20,
        } }
        onClick={ handleRevoke }
      >
        <TrashIcon />
        {'Révoquer l\'accès DPC'}
      </Button>
    </ToolbarContainer>
  )
}

Toolbar.propTypes = {
  data: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    isCompleted: PropTypes.bool,
  }),
}

const DpcAccess = () => {
  const record = useRecordContext()

  const [startDate, setStartDate] = useState(record.dpcAccess?.startDate)
  const [endDate, setEndDate] = useState(record.dpcAccess?.endDate)
  const [isCompleted, setIsCompleted] = useState(record.dpcAccess?.isCompleted ?? false)

  const data = useMemo(() => ({
    userId: record.id,
    startDate,
    endDate,
    isCompleted,
  }), [record.id, startDate, endDate, isCompleted])

  if (!record) return null

  return (
    <>
      <h1>Accès DPC</h1>

      <Stack direction="row" spacing={ 1 }>
        { record.dpcAccess === null && <Chip label="Désactivé" color="error" /> }
        { record.dpcAccess !== null && <Chip label="Activé" color="success" /> }
        { record.dpcAccess?.isExpired && <Chip label="Expiré" color="warning" /> }
      </Stack>

      <SimpleForm
        toolbar={ <Toolbar data={ data } /> }
      >
        <DateInput
          label="Date de début"
          source="dpcStartDate"
          onChange={ event => setStartDate(event.target.value) }
          defaultValue={ startDate }
        />
        <DateInput
          label="Date de fin"
          source="dpcEndDate"
          onChange={ event => setEndDate(event.target.value) }
          defaultValue={ endDate }
        />
        <BooleanInput
          label="Parcours DPC terminé"
          source="dpcIsCompleted"
          onChange={ event => setIsCompleted(event.target.checked) }
          defaultValue={ isCompleted }
        />
      </SimpleForm>
    </>
  )
}

const ToolbarContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

export default DpcAccess
