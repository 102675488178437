import { gql, useMutation, useQuery } from '@apollo/client'
import AssignmentIcon from '@mui/icons-material/Assignment'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Avatar,
  Button,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useRecordContext } from 'react-admin'

export const ALL_QUIZS = gql`
  query {
    allQuizzes {
      id
      number
      title
    }
  }
`

const UNLOCK_QUIZ = gql`
  mutation ($quiz: ID!, $user: ID!) {
    unlockQuiz(quiz: $quiz, user: $user) {
      quiz {
        id
      }
    }
  }
`

const CANCEL_UNLOCK_QUIZ = gql`
  mutation ($quiz: ID!, $user: ID!) {
    cancelUnlockQuiz(quiz: $quiz, user: $user) {
      id
    }
  }
`

const UnlockQuiz = () => {
  const record = useRecordContext()
  const [selectedQuiz, setSelectedQuiz] = useState(null)
  const allQuizs = useQuery(ALL_QUIZS)
  const [unlockQuiz] = useMutation(UNLOCK_QUIZ, {
    onCompleted: () => window.location.reload(false),
  })
  const [cancelUnlockQuiz] = useMutation(CANCEL_UNLOCK_QUIZ, {
    onCompleted: () => window.location.reload(false),
  })

  if (!record) return null

  const { unlockedQuizs } = record

  const handleSelectedQuizChange = e => setSelectedQuiz(e.target.value)

  const handleUnlock = () => {
    unlockQuiz({
      variables: {
        quiz: selectedQuiz,
        user: record.id,
      },
    })
  }

  const handleCancelUnlock = quizId => {
    cancelUnlockQuiz({
      variables: {
        quiz: quizId,
        user: record.id,
      },
    })
  }

  if (allQuizs.loading || unlockedQuizs == null) return null

  return (
    <>
      <h1>Déblocage manuel des quizs</h1>

      <form>
        <FormControl style={ { width: 600, display: 'flex' } }>
          <Select
            value={ selectedQuiz ? selectedQuiz : '' }
            onChange={ handleSelectedQuizChange }
            inputProps={ {
              name: 'quiz',
              id: 'quiz-to-unlock',
            } }
          >
            { allQuizs.data.allQuizzes
              .filter(quiz => !unlockedQuizs.map(u => u.quiz.id).includes(quiz.id))
              .map((quiz, key) => (
                <MenuItem key={ key } value={ quiz.id }>
                  <em>
                    { quiz.number ? `${quiz.number} : ` : null }
                    { quiz.title }
                  </em>
                </MenuItem>
              ),
              )
            }
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          disabled={ selectedQuiz === null }
          style={ {
            marginTop: 20,
          } }
          onClick={ handleUnlock }
        >
          Débloquer ce quiz
        </Button>
      </form>

      <List style={ { width: 600 } }>
        { unlockedQuizs.map((unlockedQuiz, key) => (
          <ListItem key={ key } dense>
            <ListItemAvatar>
              <Avatar>
                <AssignmentIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              { unlockedQuiz.quiz.number ? `${unlockedQuiz.quiz.number} : ` : null }
              { unlockedQuiz.quiz.title }
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={ () => handleCancelUnlock(unlockedQuiz.quiz.id) }
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ),
        ) }
      </List>
    </>
  )
}

UnlockQuiz.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    unlockedQuizs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
  }),
}

export default UnlockQuiz
