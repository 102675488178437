import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const cache = new InMemoryCache()

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql/`,
})

const authLink = setContext((request, { headers }) => {
  const authToken = localStorage.getItem('authToken')

  if (authToken) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${authToken}`,
      },
    }
  }

  return {
    headers: {
      ...headers,
    },
  }
})

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    authLink,
    httpLink,
  ]),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutation: {
      errorPolicy: 'all',
    },
  },
})

export default client
