import DefaultListActions from 'components/action/DefaultListActions'
import React from 'react'
import {
  Datagrid,
  DateField,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin'

const QuizFilter = props => (
  <Filter { ...props }>
    <TextInput
      label="Rechercher"
      source="search"
      alwaysOn
    />
  </Filter>
)

const QuizList = props => (
  <List
    { ...props }
    actions={ <DefaultListActions /> }
    filters={ <QuizFilter /> }
  >
    <Datagrid rowClick="show" bulkActionButtons={ false }>
      <TextField
        label="Titre du quiz"
        sortable={ false }
        source="title"
      />
      <TextField
        label="Theme"
        sortable={ false }
        source="theme.title"
      />
      <TextField
        label="Numéro"
        sortable={ true }
        source="number"
      />
      <DateField
        label="Publication"
        locales="fr-FR"
        sortable={ false }
        source="releaseDate"
        showTime
      />
    </Datagrid>
  </List>
)

export default QuizList
