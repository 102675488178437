import React from 'react'
import {
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from 'react-admin'
import { useWatch } from 'react-hook-form'

const CreateSubscription = props => {

  return (
    <>
      <Create { ...props }>
        <SimpleForm>
          <ReferenceInput
            label="Utilisateur"
            reference="user"
            source="user.id"
            perPage={ 25 }
          >
            <AutocompleteInput optionText="username" validate={ required() } />
          </ReferenceInput>
          <SelectInput
            choices={ [
              { id: 'FREEDOM_SUBSCRIPTION', name: 'Abonnement mensuel' },
              { id: 'SOLO_SUBSCRIPTION', name: 'Abonnement annuel' },
              { id: 'TEAM_SUBSCRIPTION', name: 'Abonnement team mensuel' },
              { id: 'TEAM_SUBSCRIPTION_ANNUAL', name: 'Abonnement team annuel' },
            ] }
            label="Plan"
            source="plan.id"
            validate={ required() }
          />
          <QuantityInput />
          <NumberInput
            label="Période d'essai"
            source="trialPeriodDays"
          />
        </SimpleForm>
      </Create>
    </>
  )
}

const QuantityInput = () => {
  const teamPlans = ['TEAM_SUBSCRIPTION', 'TEAM_SUBSCRIPTION_ANNUAL']
  const plan = useWatch({ name: 'plan.id' })

  const licencesOptions = Array.from({ length: 99 }, (_, i) => ({
    id: i + 2,
    name: i + 2,
  }))

  return (
    plan && teamPlans.includes(plan) ? (
      <SelectInput
        label="Nombre d'utilisateurs"
        source="quantity"
        choices={ licencesOptions }
        validate={ required() }
      />
    ) : null
  )
}

export default CreateSubscription
