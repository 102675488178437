import React from 'react'
import { ExportButton, TopToolbar } from 'react-admin'

const RankingListActions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
)

export default RankingListActions
