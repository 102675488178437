import React from 'react'
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin'

const ThemeFilter = props => (
  <Filter { ...props }>
    <TextInput
      label="Rechercher"
      source="search"
      alwaysOn
    />
  </Filter>

)

const ListTheme = props => (
  <List
    { ...props }
    filters={ <ThemeFilter /> }
  >
    <Datagrid rowClick="show" bulkActionButtons={ false }>
      <TextField
        label="Theme"
        sortable={ false }
        source="title"
      />
    </Datagrid>
  </List>
)

export default ListTheme
