import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import DescriptionIcon from '@mui/icons-material/Description'
import DownloadIcon from '@mui/icons-material/Download'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import TimeSpentField from 'components/form-field/TimeSpentField'
import React from 'react'
import {
  ArrayField,
  BooleanField,
  Datagrid,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin'

const ShowTitle = () => {
  const record = useRecordContext()
  if (!record) return null
  return <span>{record.title}</span>
}

const Export = () => {
  const record = useRecordContext()

  if (!record) return null

  const downloadExport = () => {
    const titleFormatted = record.title.toLowerCase().replace(/ /g, '-').substring(0, 15)

    fetch(`${process.env.REACT_APP_API_URL}/exports/quiz/${record.id}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then(res => res.blob())
      .then(data => {
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(data)
        a.download = `quiz-${record.number ?? titleFormatted}.csv`
        a.click()
      })
  }

  return (
    <ExportButton variant="outlined" onClick={ downloadExport }>
      <ExportIcon />
      <Typography noWrap>Export</Typography>
    </ExportButton>
  )
}

const QuizShow = props => (
  <Show { ...props } title={ <ShowTitle /> }>
    <TabbedShowLayout>
      <Tab
        icon={ <DescriptionIcon /> }
        label="Résumé"
      >
        <TextField
          label="Identifiant"
          source="id"
        />
        <TextField
          label="Theme"
          source="theme.title"
        />
        <TextField
          label="Titre du quiz"
          source="title"
        />
        <TextField
          label="Numéro"
          source="number"
        />
        <Export />
      </Tab>

      <Tab
        icon={ <AssignmentTurnedInIcon /> }
        label="Questions"
      >
        <ArrayField
          label="Questions"
          source="questions"
        >
          <Datagrid bulkActionButtons={ false }>
            <TextField
              label="Intitulé"
              source="title"
            />
            <TextField
              label="Sujet"
              source="topic"
            />
            <TextField
              label="Type"
              source="type"
            />

            <ArrayField
              label="Réponses"
              source="answers"
            >
              <Datagrid bulkActionButtons={ false }>
                <TextField
                  label="Réponse"
                  source="title"
                />
                <BooleanField
                  label="Valide"
                  source="valid"
                />
                <BooleanField
                  label="Grave"
                  source="important"
                />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>
      </Tab>

      <Tab
        icon={ <InsertChartIcon /> }
        label="Statistiques"
      >
        <NumberField
          label="Note moyenne des utilisateurs (sur 5)"
          source="rating"
        />
        <NumberField
          label="Nombre total des utilisateurs qui ont votés"
          source="statistics.ratingCount"
        />
        <NumberField
          label="Nombre de premières sessions terminées"
          source="statistics.finishedInitialSessionCount"
        />
        <NumberField
          label="Nombre de sessions commencées"
          source="statistics.sessionsCount"
        />
        <NumberField
          label="Nombre de sessions terminées"
          source="statistics.completeSessionsCount"
        />
        <NumberField
          label="Score moyen"
          source="statistics.avgScore"
        />
        <TimeSpentField
          label="Temps moyen passé"
          source="statistics.avgTimeSpent"
        />
        <NumberField
          label="Total de réponses graves"
          source="statistics.importantCount"
        />
        <NumberField
          label="Total de bonnes réponses"
          source="statistics.validCount"
        />
        <NumberField
          label="Total de réponses passées"
          source="statistics.skippedCount"
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default QuizShow

const ExportButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  cursor: 'pointer',
  maxWidth: 400,
}))

const ExportIcon = styled(DownloadIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))
