import React from 'react'
import { Datagrid, List, TextField } from 'react-admin'

const ProductList = props => (
  <List
    { ...props }
    pagination={ null }
  >
    <Datagrid bulkActionButtons={ false }>
      <TextField
        label="Nom de l'offre"
        sortable={ false }
        source="title"
      />
      <TextField
        label="ID Stripe"
        source="stripeId"
      />
    </Datagrid>
  </List>
)

export default ProductList
