import { styled } from '@mui/material/styles'
import { FormatButtons, RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text'
import React from 'react'
import {
  Create,
  required,
  SimpleForm,
} from 'react-admin'

const DailyQuestionCreate = props => (
  <Create redirect="list" { ...props }>
    <SimpleForm>
      <RichTextInput
        label="Intitulé"
        source="title"
        toolbar={<ToolBar />}
        validate={ required() }
        fullWidth
      />
      <RichTextInput
        label="Answer"
        source="answer"
        toolbar={<ToolBar />}
        validate={ required() }
        fullWidth
      />
    </SimpleForm>
  </Create>
)

const ToolBar = () => (
  <Root>
    <RichTextInputToolbar>
      <FormatButtons />
    </RichTextInputToolbar>
  </Root>
)

const Root = styled('div')(() => ({
  'alignSelf': 'flex-start',
  'button': {
    display: 'none',
  },
  'button[aria-label="Bold"]': {
    display: 'block',
    borderRadius: 0,
  },
}))

export default DailyQuestionCreate
