import React from 'react'
import {
  Create,
  regex,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin'

const CreatePharmacy = props => (
  <Create { ...props }>
    <SimpleForm>
      <TextInput
        label="Nom de la pharmacie"
        source="name"
        validate={ required() }
        fullWidth
      />
      <TextInput
        label="Adresse de la pharmacie"
        source="address"
        fullWidth
      />
      <TextInput
        label="Code postal de la pharmacie"
        source="zipCode"
        validate={ [required(), regex(/^\d{5}$/)] }
        fullWidth
      />
      <TextInput
        label="Ville de la pharmacie"
        source="city"
        fullWidth
      />
      <TextInput
        label="Pays de la pharmacie"
        source="country"
        fullWidth
      />
      <TextInput
        label="Groupement"
        source="organization"
        fullWidth
      />
    </SimpleForm>
  </Create>
)

export default CreatePharmacy
