import CustomToolbar from 'components/toolbar'
import React from 'react'
import {
  ArrayInput,
  Edit,
  FormTab,
  maxLength,
  NumberInput,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin'

const EditTheme = props => (
  <Edit { ...props }>
    <TabbedForm toolbar={ <CustomToolbar /> }>
      <FormTab label="Général">
        <TextInput
          label="Titre du theme"
          source="title"
          validate={ required() }
          fullWidth
        />
      </FormTab>

      <FormTab label="Essentiel">
        <ArrayInput
          label="Essentiels"
          source="essential.sections"
          fullWidth
        >
          <SimpleFormIterator fullWidth getItemLabel={ index => `Essentiel ${index + 1} :` }>
            <TextInput
              label="Titre"
              source="title"
              validate={ required() }
              fullWidth
            />
            <TextInput
              label="Contenu"
              source="content"
              validate={ required() }
              fullWidth
              multiline
            />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput
          label="Bibliographie"
          source="essential.bibliography"
          fullWidth
        >
          <SimpleFormIterator fullWidth getItemLabel={ index => `Essentiel ${index + 1} :` }>
            <TextInput
              label="Titre"
              source="title"
              validate={ [required(), maxLength(255)] }
              fullWidth
            />
            <TextInput
              label="Description"
              source="description"
              validate={ [required(), maxLength(255)] }
              multiline
              fullWidth
            />
            <TextInput
              label="Lien"
              source="link"
              validate={ maxLength(255) }
              fullWidth
            />
            <NumberInput
              label="Position"
              source="position"
              validate={ required() }
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
)

export default EditTheme
