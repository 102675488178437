import RankingListActions from 'components/action/RankingListActions'
import React from 'react'
import {
  Datagrid,
  EmailField,
  Filter,
  List,
  NumberField,
  SelectInput,
  TextField,
} from 'react-admin'

const UserFilter = props => (
  <Filter { ... props }>
    <SelectInput
      choices={ [
        { id: 'Médoc de bronze', name: 'Médoc de bronze' },
        { id: 'Médoc d\'argent', name: 'Médoc d\'argent' },
        { id: 'Médoc d\'or', name: 'Médoc d\'or' },
        { id: 'Médoc de platine', name: 'Médoc de platine' },
        { id: 'Médoc de diamant', name: 'Médoc de diamant' },
      ] }
      label="Badge"
      source="badge"
      alwaysOn
    />
  </Filter>
)

const RankingList = props => (
  <List
    { ...props }
    filters={ <UserFilter /> }
    actions={ <RankingListActions /> }
    title="Classements"
  >
    <Datagrid bulkActionButtons={ false }>
      <TextField
        label="Nom"
        sortable={ false }
        source="lastname"
      />
      <TextField
        label="Prénom"
        sortable={ false }
        source="firstname"
      />
      <TextField
        label="Nom d'utilisateur"
        sortable={ false }
        source="username"
      />
      <EmailField
        label="Email"
        sortable={ false }
        source="email"
      />
      <TextField
        label="Job"
        sortable={ false }
        source="job"
      />
      <NumberField
        label="Score cumulé"
        sortable={ false }
        source="calculatedSumScore"
      />
      <TextField
        label="Badge"
        sortable={ false }
        source="badge"
      />
    </Datagrid>
  </List>
)

export default RankingList
