import React from 'react'
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
} from 'react-admin'

const PlanList = props => (
  <List
    { ...props }
    pagination={ null }
  >
    <Datagrid rowClick="edit" bulkActionButtons={ false }>
      <TextField
        label="ID du plan"
        sortable={ false }
        source="id"
      />
      <TextField
        label="Nom du plan"
        sortable={ false }
        source="title"
      />
      <TextField
        label="Nom du produit"
        sortable={ false }
        source="product.title"
      />
      <TextField
        label="ID Stripe"
        sortable={ false }
        source="stripeId"
      />
      <TextField
        label="ID Apple"
        sortable={ false }
        source="appleId"
      />
      <TextField
        label="ID Google"
        sortable={ false }
        source="googleId"
      />
      <FunctionField
        label="Prix"
        render={ plan => `${plan.amount / 100} €/${plan.interval}` }
      />
    </Datagrid>
  </List>
)

export default PlanList
