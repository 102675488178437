import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'
import { mapGetListVariables, mapGetManyVariables } from 'data-mapper'
import { GET_LIST, GET_MANY, GET_ONE } from 'react-admin'

const getList = (type, params) => apolloClient
  .query({
    query: gql`
      query ($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: ReminderFilter) {
          allReminders(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
              id
              days
              createdAt
              user {
                  id
                  firstname
                  lastname
                  email
                  username
              }
          }
          _allRemindersMeta(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
              count
          }
      }
  `,
    variables: type === GET_MANY ? mapGetManyVariables(params) : mapGetListVariables(params),
  })
  .then(({ data }) => ({
    data: data.allReminders,
    total: data._allRemindersMeta.count,
  }))

const getOne = params => apolloClient
  .query({
    query: gql`
      query ($id: ID!) {
          reminder(id: $id) {
              id
              days
              createdAt
              user {
                  id
                  firstname
                  lastname
                  email
                  username
              }
          }
      }
  `,
    variables: {
      id: params.id,
    },
  })
  .then(({ data }) => ({
    data: data.reminder,
  }))

export default (type, params) => {
  switch (type) {
    case GET_LIST: return getList(type, params)
    case GET_ONE: return getOne(params)
    case GET_MANY: return getList(type, params)
    default: return new Promise()
  }
}
