import DefaultListActions from 'components/action/DefaultListActions'
import React from 'react'
import {
  Datagrid,
  EmailField,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin'

const UserFilter = props => (
  <Filter { ... props }>
    <TextInput
      label="Rechercher"
      source="q"
      alwaysOn
    />
  </Filter>
)

const UserList = props => (
  <List
    { ...props }
    filters={ <UserFilter /> }
    actions={ <DefaultListActions /> }
    title="Utilisateurs"
  >
    <Datagrid rowClick="show" bulkActionButtons={ false }>
      <TextField
        label="Nom d'utilisateur"
        sortable={ false }
        source="username"
      />
      <TextField
        label="Nom"
        sortable={ false }
        source="lastname"
      />
      <TextField
        label="Prénom"
        sortable={ false }
        source="firstname"
      />
      <EmailField
        label="E-mail"
        sortable={ false }
        source="email"
        emptyText="<anonyme>"
      />
      <TextField
        label="Job"
        sortable={ false }
        source="job"
      />
      <TextField
        label="Groupement"
        sortable={ false }
        source="organization"
      />
      <TextField
        label="ID Stripe"
        sortable={ false }
        source="stripeId"
      />
    </Datagrid>
  </List>
)

export default UserList
