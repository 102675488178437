export const mapDataToEssentialInput = essential => ({
  sections: mapDataToSectionInput(essential.sections),
  bibliography: mapDataToBibliographyInput(essential.bibliography),
})

const mapDataToSectionInput = sections => sections.map(section => ({
  id: section.id,
  title: section.title,
  content: section.content,
}))

const mapDataToBibliographyInput = bibliography => bibliography.map(item => ({
  id: item.id,
  title: item.title,
  description: item.description,
  link: item.link,
  position: item.position,
}))
