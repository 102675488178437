import accountDeletionRequest from 'data-provider/accountDeletionRequest'
import commentProvider from 'data-provider/comment'
import couponProvider from 'data-provider/coupon'
import dailyQuestionProvider from 'data-provider/dailyQuestion'
import pharmacyProvider from 'data-provider/pharmacy'
import planProvider from 'data-provider/plan'
import productProvider from 'data-provider/product'
import quizProvider from 'data-provider/quiz'
import rankingProvider from 'data-provider/ranking'
import reminderProvider from 'data-provider/reminder'
import subscriptionProvider from 'data-provider/subscription'
import taxRateProvider from 'data-provider/taxRate'
import themeProvider from 'data-provider/theme'
import userProvider from 'data-provider/user'

export default (type, resource, params) => {

  switch (resource) {
    case 'quiz': return quizProvider(type, params)
    case 'theme': return themeProvider(type, params)
    case 'user': return userProvider(type, params)
    case 'pharmacy': return pharmacyProvider(type, params)
    case 'comment': return commentProvider(type, params)
    case 'product': return productProvider(type, params)
    case 'taxRate': return taxRateProvider(type, params)
    case 'plan': return planProvider(type, params)
    case 'subscription': return subscriptionProvider(type, params)
    case 'coupon': return couponProvider(type, params)
    case 'ranking': return rankingProvider(type, params)
    case 'reminder': return reminderProvider(type, params)
    case 'dailyQuestion': return dailyQuestionProvider(type, params)
    case 'accountDeletionRequest': return accountDeletionRequest(type, params)
    default: return new Promise()
  }
}
