import React from 'react'
import { SaveButton, Toolbar } from 'react-admin'

const CustomToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
)

export default CustomToolbar
