import { styled } from '@mui/material/styles'
import CustomToolbar from 'components/toolbar'
import { FormatButtons, RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text'
import React from 'react'
import {
  Edit,
  required,
  SimpleForm,
} from 'react-admin'

const DailyQuestionEdit = props => (
  <Edit { ...props }>
    <SimpleForm toolbar={ <CustomToolbar /> }>
      <RichTextInput
        label="Intitulé"
        source="title"
        toolbar={<ToolBar />}
        validate={ required() }
        fullWidth
      />
      <RichTextInput
        label="Answer"
        source="answer"
        toolbar={<ToolBar />}
        validate={ required() }
        fullWidth
      />
    </SimpleForm>
  </Edit>
)

const ToolBar = () => (
  <Root>
    <RichTextInputToolbar>
      <FormatButtons />
    </RichTextInputToolbar>
  </Root>
)

const Root = styled('div')(() => ({
  'alignSelf': 'flex-start',
  'button': {
    display: 'none',
  },
  'button[aria-label="Bold"]': {
    display: 'block',
    borderRadius: 0,
  },
}))

export default DailyQuestionEdit
